import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RegisterContext } from '../auth/context/registerContext';
import IdentityBtn from '../buttons/identityBtn';
import { FaDiscord, FaTelegram, FaTimes } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from 'react-icons/fa';
import { FaTerminal } from 'react-icons/fa';
import { FaPrint } from 'react-icons/fa';

import { Link as Links } from 'react-scroll';
import { VscThreeBars } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { logOutAction } from '../../api/auth';
import { setSelectedSubPage } from '../../redux/reducers/userStateReducer';
import { checkIsAdministrator } from '../../utils/checkIsAdministrator';
import Cookies from 'js-cookie';
import { CartContext } from '../auth/context/cartContext';
import { useMediaQuery } from 'react-responsive';

const userSubNavl = ['STORE', 'PROFILE', 'LIBRARY', 'SALES', 'SETTINGS'];

const comingSoonList = [
  // "Battlecry",
  // "Supporters",
  // "Assets",
  // "Features",
  // "Join",
  // "Socials",
];

const Sidebar = ({
  className,
  pageType = 'home',
  // userSubNavList = userSubNavl,
  isComingSoon,
}) => {
  const [activeSidebar, setActiveSidebar] = useState(1);
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const { updateCart } = useContext(CartContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slideIn, setSlideIn] = useState(false);
  let userSubNavs = isComingSoon ? comingSoonList : userSubNavl;
  const [userNavSelected, setUserNavSelected] = useState('STORE');
  const [toggleVisAdmin, setToggleVisAdmin] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 300, maxWidth: 768 });

  let user = {};
  const logout = async () => {
    await dispatch(logOutAction({ showToast: true }));
    navigate('/');
    updateCart([]);
  };

  if (typeof window !== 'undefined' && user) {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      user = JSON.parse(userCookie);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (checkIsAdministrator(user?.team_roles)) {
        setToggleVisAdmin(true);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [toggleVisAdmin, user.team_roles]);
  const location = useLocation();

  // Access the pathname
  const pathname = location.pathname;
  return (
    <section
      onClick={() => {
        setSlideIn((old) => true);
        setTimeout(() => {
          setSlideIn(false);
          setShowRegModal({ ...showRegModal, isSidebarOpen: false });
        }, 200);
      }}
      className={`landing__overlay ${
        slideIn ? 'landing__overlay-dismiss' : ''
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='landing__sidebar-toggle-close'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        {!showRegModal.isSidebarOpen ? (
          <VscThreeBars
            className='landing__sidebar-toggler-close'
            style={{ color: 'white', fontSize: '2rem' }}
          />
        ) : (
          <FaTimes
            onClick={() => {
              setSlideIn((old) => true);
              setTimeout(() => {
                setSlideIn(false);
                setShowRegModal({ ...showRegModal, isSidebarOpen: false });
              }, 200);
            }}
            className='landing__sidebar-toggler'
            style={{ color: 'white', fontSize: '2rem' }}
          />
        )}
      </div>
      <section
        onClick={(e) => e.stopPropagation()}
        className={`sidebar ${className}`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className='sidebar__inner-container'
        >
          <section className='sidebar__auth-sec'>
            {user && user.id ? (
              <>
                {pageType !== 'coming soon' && (
                  <>
                    <li
                      onClick={() => {
                        navigate(`/`);
                        setShowRegModal({
                          ...showRegModal,
                          isSidebarOpen: false,
                        });
                      }}
                      className={``}
                    >
                      <button
                        onClick={(e) => {
                          navigate('/');
                          setShowRegModal({
                            ...showRegModal,
                            isSidebarOpen: false,
                          });
                        }}
                        className='user__upload-products-btn'
                        style={{ width: '100%' }}
                      >
                        <div className='user__upload-products-btn-inner'>
                          HOME
                        </div>
                      </button>
                    </li>

                    <li
                      onClick={() => {
                        navigate(`/user/profile`);
                        setShowRegModal({
                          ...showRegModal,
                          isSidebarOpen: false,
                        });
                      }}
                      className={`landing__nav-item login `}
                    >
                      <span className='landing__nav-link'>USER DASHBOARD</span>
                    </li>

                    {toggleVisAdmin && !isMobile && (
                      <li
                        onClick={() => {
                          navigate(`/admin/product`);
                          setShowRegModal({
                            ...showRegModal,
                            isSidebarOpen: false,
                          });
                        }}
                        className={`landing__nav-item login `}
                      >
                        <Link className='landing__nav-link' to={'#'}>
                          ADMIN DASHBOARD
                        </Link>
                      </li>
                    )}
                  </>
                )}
                {pageType === 'user' && pathname !== '/admin/product' && (
                  <>
                    {userSubNavs.map((item, index) => {
                      return (
                        <li
                          key={item}
                          onClick={() => {
                            setUserNavSelected(item);
                            navigate(`/user/${item.toLowerCase()}`);
                            setShowRegModal({
                              ...showRegModal,
                              isSidebarOpen: false,
                            });
                            dispatch(
                              setSelectedSubPage({
                                selected: item.toLowerCase(),
                              })
                            );
                          }}
                          className={`landing__nav-item login ${
                            userNavSelected === item ? 'signup' : 'login'
                          }`}
                        >
                          <Link className='landing__nav-link' to={'#'}>
                            {item}
                          </Link>
                        </li>
                      );
                    })}
                  </>
                )}
              </>
            ) : isComingSoon ? (
              <div
                style={{
                  flexDirection: 'column',
                  width: '90%',
                }}
                className='coming__soon-navbar-request-access'
              >
                <button
                  style={{ width: '90%' }}
                  onClick={() => {
                    navigate('/');
                    setShowRegModal((old) => ({
                      ...old,
                      isSidebarOpen: false,
                    }));
                  }}
                  className='coming__soon-navbar-request-btn'
                >
                  <span>Home</span>
                </button>
                <button
                  style={{ width: '90%' }}
                  onClick={() => {
                    setShowRegModal((old) => ({
                      ...old,
                      isSidebarOpen: false,
                      login: true,
                    }));
                  }}
                  className='coming__soon-navbar-request-btn orange'
                >
                  <span>Sign in</span>
                </button>
                <button
                  style={{ width: '90%' }}
                  onClick={() => {
                    setShowRegModal((old) => ({
                      ...old,
                      isSidebarOpen: false,
                      isComingSoonOpen: true,
                    }));
                  }}
                  className='coming__soon-navbar-request-btn orange'
                >
                  <span>Join waitlist</span>
                </button>
              </div>
            ) : (
              <>
                <div
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    setShowRegModal({
                      ...showRegModal, isSidebarOpen: false,
                      signin: !showRegModal.signin,
                    });
                  }}
                  className='landing__nav-item login'
                >
                 <Link
                    onClick={(e) => e.stopPropagation()}
                    className='landing__nav-link'
                    to={'#'}
                  >
                    Sign&nbsp;In
                  </Link>
                </div>

                 <li
                  onClick={(e) => {
                    setShowRegModal({
                      ...showRegModal, isSidebarOpen: false,
                      register: !showRegModal.register,
                    });
                  }}
                  className={'landing__nav-item signup'}
                >
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    className='landing__nav-link'
                    to={'#'}
                  >
                    Sign&nbsp;Up
                  </Link>
                </li> 
              </>
            )}
          </section>

          {pageType === 'home' && !isComingSoon && (
            <section className='sidebar__tags'>
              {/* <Links offset={-100} to='freebies' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(1)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 1 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 1 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'WEEKLY FREEBIES'}
                />
              </Links>
              <Links offset={-100} to='picks' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(2)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 2 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 2 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'DAILY PICKS'}
                />
              </Links> */}
              <Links offset={-100} to='products' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(3)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 3 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 3 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'ALL PRODUCTS'}
                />
              </Links>
              <Links offset={-200} to='collections' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(4)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 4 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 4 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'RECENT UPLOADS'}
                />
              </Links>
              <Links offset={-100} to='earn' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(5)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 5 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 5 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'LOOOTY EARN'}
                />
              </Links>
              <Links offset={-100} to='awesome' spy={true} smooth={true}>
                <IdentityBtn
                  onClick={() => setActiveSidebar(6)}
                  style={{
                    width: '100%',
                    fontSize: '.7rem',
                    borderLeft: `1rem solid ${
                      activeSidebar === 6 ? '#7C187A' : '#353449'
                    }`,
                    transform: `translateX(${
                      activeSidebar === 6 ? '0rem' : '-2rem'
                    }) skewX(-25deg)`,
                  }}
                  text={'AWESOME ARTS'}
                />
              </Links>
            </section>
          )}

          {/* coming soon */}
          {isComingSoon && (
            <section
              className='sidebar__tags'
              style={{ height: '10vh' }}
            ></section>
          )}

          <section className='sidebar__footer'>
            {/* <div className="sidebar__top">
              <LoootyLogoStatic />
            </div> */}

            <ul className='sidebar__socials'>
              {/* {!isComingSoon && (
                <li className="sidebar__socials-item">
                  <FaMailBulk />
                  Newsletter
                </li>
              )} */}
              <a
                href='https://instagram.com/loootystore?igshid=NTc4MTIwNjQ2YQ=='
                rel='noopener noreferrer'
                target='_blank'
              >
                <FaInstagram />
                Instagram
              </a>

              <a
                href='https://twitter.com/loootystore?t=nfX-NSZrbTszIxoKLx-7kA&s=09'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FaXTwitter />
                X formerly Twitter
              </a>

              <a
                href='https://t.me/loootystore'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FaTelegram />
                Telegram
              </a>

              <a
                href='https://discord.gg/ubJmSxEQhf'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FaDiscord />
                Discord
              </a>
            </ul>

            <ul className='sidebar__bottom'>
              {/* <li className="sidebar__bottom-item">
                  <FaQq />
                  F.A.Q
                </li> */}
              <a href='/terms/conditions' className='sidebar__bottom-item'>
                <FaTerminal />
                Terms of Service
              </a>
              <a href='/privacy/policy' className='sidebar__bottom-item'>
                <FaPrint />
                Privacy Policy
              </a>
              {user && !isComingSoon && (
                <li
                  style={{ marginTop: '1rem' }}
                  onClick={() => {
                    logout();
                    setShowRegModal({
                      ...showRegModal,
                      isSidebarOpen: false,
                    });
                  }}
                  className={'landing__nav-item logout'}
                >
                  <p className='landing__nav-link'>Log&nbsp;Out</p>
                </li>
              )}
            </ul>
          </section>
        </div>
      </section>
    </section>
  );
};

export default Sidebar;
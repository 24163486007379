import EarlyAccessSell from '../../component/admin/modals/slogan/EarlyAccessSell';
import { useState } from 'react';

const StartSelling = () => {
  const [showEarlyAccessSell, setShowEarlyAccessSell] = useState(false);
  return (
    <div className='landing__start-selling-container'>
      <h1 className='landing__start-selling-text'>Start Selling on Loooty</h1>

      <h3 className='landing__start-selling-desc'>
        Share your art resources with the world and keep as much as 90% of
        revenues
      </h3>

      <button
        onClick={() => setShowEarlyAccessSell(!showEarlyAccessSell)}
        className='landing__slogan-browse'
        style={{ backgroundColor: 'rgb(255, 151, 0)' }}
      >
        <span className='landing__slogan-browse-text'>BECOME A CREATOR</span>
      </button>
      {showEarlyAccessSell && (
          <EarlyAccessSell toggleShowModal={() => setShowEarlyAccessSell(false)} />
        )}
    </div>
  );
};

export default StartSelling;
